export const Star = () => {
  return (
    <>
      <svg
        width="31"
        height="32"
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.303 8.83201L22.7422 16.5972L22.5077 16.8747L22.6992 17.1834L28.0569 25.8227L18.6444 21.9826L18.308 21.8454L18.0736 22.1228L11.5127 29.8881L12.2563 19.7495L12.2829 19.3872L11.9465 19.25L2.53394 15.4098L12.406 12.984L12.7588 12.8973L12.7854 12.535L13.5289 2.39649L18.8867 11.0358L19.0781 11.3445L19.4309 11.2578L29.303 8.83201Z"
          stroke="#1A1A1A"
        />
      </svg>
    </>
  );
};
