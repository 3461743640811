import React, { useState, useEffect, useRef } from "react";
import {
  AbsoluteImage,
  AnswerContainer,
  AppCorner,
  BackCenter,
  BodyInformation,
  BodyWrapper,
  BoxArea,
  BoxContainer,
  BoxImage,
  BoxText,
  BusinessScale,
  BusinessTool,
  ButtonArea,
  ButtonPress,
  ButtonPressBlack,
  CenterMage,
  CommentAreaDiv,
  CommentBox,
  Commentor,
  ContainerHolderDiv,
  DisplayArea,
  DivScroll,
  Footer,
  FooterContent,
  Frequently,
  Header,
  ImageCollab,
  ImageHolder,
  LeftMage,
  LogoArea,
  LogoDiv,
  LongBox,
  MageStory,
  MainWrapper,
  MerchroEnglish,
  MerchroNavsArea,
  // NavArea,
  Navigator,
  Questionaira,
  Questions,
  QuestionsAre,
  RightMage,
  ScrollEffect,
  ShortBox,
  ShortTextInBox,
  SlantDiv,
  TalesHandler,
  TalesStory,
  TextArea,
  TextDiv,
  TextInBox,
  ToolInformation,
  UseFulArea,
  ViewImage,
} from "./style";
import {
  AiOutlineRight,
  AiOutlineLeft,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import {
  Bounce,
  Fade,
  Zoom,
  Reveal,
} from "react-awesome-reveal";
import { Star } from "../assets/star";
import { FaTwitter , FaInstagram } from "react-icons/fa";

interface Question {
  id: number;
  text: string;
  answer: string;
}

const MainContent = () => {
  const [toggledQuestion, setToggledQuestion] = useState<number | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % numberOfSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + numberOfSlides) % numberOfSlides
    );
  };

  const numberOfSlides = 2;

  const handleToggle = (questionId: number) => {
    setToggledQuestion((prev) => (prev === questionId ? null : questionId));
  };

  const questions: Question[] = [
    {
      id: 1,
      text: "What is Mage?",
      answer:
        "Mage simplifies your financial operations, allowing you to focus on your creative work. We handle payments, offer credit options tailored to your business needs, and manage benefits for your freelance team, all in one place.",
    },
    {
      id: 2,
      text: "I run a startup, can I use Mage?",
      answer:
        "Absolutely! Mage is designed to support businesses of all sizes, from solo entrepreneurs to growing startups and established creative agencies.",
    },
    {
      id: 3,
      text: "Is my financial information secure with Mage?",
      answer:
        "Security is our top priority. Mage uses advanced encryption and security protocols to ensure that your financial data and transactions are always protected.",
    },
    {
      id: 4,
      text: "Is there a cost associated with using Mage?",
      answer:
        "We offer transparent pricing with no hidden fees. You can choose from different plans based on your business size and needs. ",
    },
  ];

  const businessScaleRef = useRef<HTMLDivElement>(null);

  const handleScrollToBusinessScale = () => {
    if (businessScaleRef.current) {
      businessScaleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const advantage = [
    {
      image: "/banknotes.png",
      topic: "Link your platforms",
      desc:
        "You can connect the platforms your earn from and your social accounts when you sign up on Mage.",
    },
    {
      image: "/circle-stack.png",
      topic: "We cover your payments",
      desc:
        "Use Mage to apply for royalty advances, invoice financing, or project funding.",
    },
    {
      image: "/arrows-right-left.png",
      topic: "Pay back and build credit",
      desc:
        "You pay us back the amount we covered and we make sure you get the credit you deserve.",
    },
  ];

  const handleButtonClick = (path: string) => {
    window.location.href = `https://app.trymage.com/${path}`;
  };

  const handlePathClick = (path:string) => {
    window.location.href = path;
  };

  // const handleAppStore = () => {
  //   window.open("https://apps.apple.com/us/app/merchro/id6448023194", "_blank");
  // };

  // const handlePlayStore = () => {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.merchro.creators.app&pli=1",
  //     "_blank"
  //   );
  // };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const originalContent = container.children[0];

    const cloneContent = () => {
      const clone = originalContent?.cloneNode(true);
      container?.appendChild(clone);
    };

    const handleAnimationIteration = () => {
      const firstChild = container?.firstChild;
      if (firstChild) {
        container?.removeChild(firstChild);
      }
      cloneContent();
    };
    cloneContent();

    container?.addEventListener("animationiteration", handleAnimationIteration);
    return () => {
      container?.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    };
  }, []);

  const ContainerHolder: React.FC<{ category: string }> = ({ category }) => (
    <ContainerHolderDiv>
      <Star />
      <p>{category}</p>
    </ContainerHolderDiv>
  );
  const categories = [
    "MUSIC",
    "DESIGN",
    "FASHION",
    "ART",
    "CONTENT",
    "MOVIES",
    "GAMING",
  ];
  const duplicationCount = 1000;
  const duplicatedContent = Array.from(
    { length: duplicationCount },
    (_, index) => (
      <ContainerHolder
        key={index}
        category={categories[index % categories.length]}
      />
    )
  );

  return (
    <>
      <MainWrapper>
        <Header ref={businessScaleRef}>
          <Zoom triggerOnce>
            <LogoDiv>
              <img src="/logosvg.svg" alt="logo" />
            </LogoDiv>
          </Zoom>

          <ButtonArea className="home-page">
            <p className="hide-mobile" style={{ marginRight: 20 }} onClick={() => handlePathClick('https://invest.trymage.com')}>Invest</p>
            <p className="hide-mobile" style={{ marginRight: 20 }} onClick={() => handleButtonClick('sign-up')}>Get started</p>
            <p style={{ marginRight: 20 }} onClick={() => handleButtonClick('login')}>Login</p>
            {/* <img
              src="/iconapp.png"
              alt="plystore"
              onClick={handleAppStore}
              width="25px"
            />
            <img
              src="/iconplay.png"
              alt="plystore"
              onClick={handlePlayStore}
              width="20px"
            /> */}
          </ButtonArea>
          {/* <HamburgerDiv>
            <AiOutlineMenu color="white" size={20} />
          </HamburgerDiv> */}
        </Header>

        <BodyWrapper>
          <BackCenter>
            <ButtonPressBlack>Merchro is now Mage</ButtonPressBlack>
            <TextArea style={{ width: "auto" }}>
              <>
                <h3>We Help Creatives Manage Money Stuff</h3>
              </>
              <>
                <p>
                 Custom financial tools and services
                  <br /> for professional creatives
                </p>
              </>

              <ButtonPress onClick={() => handleButtonClick('sign-up')}>Get Started</ButtonPress>
            </TextArea>
          </BackCenter>
          <ViewImage>
            <img src="/people.png" alt="landing" />
          </ViewImage>
        </BodyWrapper>

        <BodyInformation>
          <ImageCollab>
            <img src="/groups5.png" alt="collab" />
          </ImageCollab>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextArea width="65%" bottom="70px">
              <>
                <h2
                  style={{
                    color: "#222",
                  }}
                >
                  Mage is simplifying business for creatives
                </h2>
                <p style={{ color: "#222" }}>
                  Join hundreds of professional creatives on Mage today
                </p>
              </>
            </TextArea>
          </div>

          <BoxArea>
            <LongBox>
              <BoxContainer>
                <AbsoluteImage
                  mobile="none"
                  top="auto"
                  width="200px"
                  size="40px"
                  left="43%"
                  image="20px"
                  style={{
                    marginTop: "140px",
                  }}
                >
                  <span>
                    <Zoom triggerOnce>
                      <img
                        src="/card.png"
                        alt="money"
                        style={{
                          width: "150px",
                          marginLeft: 50
                        }}
                      />
                    </Zoom>
                  </span>
                </AbsoluteImage>
                <BoxText>
                  <TextInBox>
                    <p style={{ width: "100%" }}>
                      Get paid from your
                      <br />
                      platforms and clients
                    </p>
                    <span className="box-text-line">
                      Collect all your money in one place. Plus a bonus of 8% yearly on money earned.
                    </span>
                  </TextInBox>
                </BoxText>
                <BoxImage
                  top="120px"
                  style={{
                    width: "auto",
                    height: "100%",
                    marginRight: "20px",
                  }}
                >
                  <img src="/image4.png" alt="pic" />
                </BoxImage>
              </BoxContainer>
            </LongBox>
            <ShortBox>
              <ShortTextInBox>
                <div>
                  <img src="/split.png" alt="logo" />
                </div>
                <p>Collaborate without stress</p>
                <span>
                  Easily split funds from your projects and royalties with our unique split tool.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <ShortBox>
              <ShortTextInBox>
                <div>
                  <img src="/dollar.png" alt="logo" />
                </div>
                <p>Scale your business with insights</p>
                <span>
                  We analyze your financial stats and recommend actions to help your run your business better.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <LongBox>
              <div
                style={{
                  position: "absolute",
                }}
              ></div>
              <BoxContainer>
                <BoxText>
                  <TextInBox>
                    <p>Enjoy reliable financing when you need it</p>
                    <span className="box-text-line">
                      Access funding when you need it through our unique credit offerings.
                    </span>
                  </TextInBox>
                </BoxText>
                <BoxImage
                  style={{
                    width: "auto",
                    height: "65%",
                    marginRight: "-30px",
                    marginTop: "10px",
                  }}
                >
                  <img src="/frame.svg" alt="pic" />
                </BoxImage>
              </BoxContainer>
            </LongBox>
          </BoxArea>
        </BodyInformation>

        <>
          <SlantDiv ref={containerRef}>
            <div>{duplicatedContent}</div>
          </SlantDiv>
        </>

        <BusinessScale>
          <ButtonPressBlack
            style={{
              backgroundColor: "#2B892E",
              color: "white",
            }}
          >
           How it works
          </ButtonPressBlack>
          <TextArea width="70%" bottom="50px">
            <Zoom triggerOnce>
              <h2>
                It's super easy to <br />
                get started
              </h2>
            </Zoom>
          </TextArea>
          <ImageHolder>
            <img src="/image.png" alt="landing" />
          </ImageHolder>
          <BusinessTool>
            {advantage.map((info) => (
              <ToolInformation>
                <div>
                  <div>
                    <Reveal>
                      <img src={info.image} alt="stack" />
                    </Reveal>
                  </div>
                  <p>{info.topic}</p>
                </div>
                <Fade>
                  <span>{info.desc}</span>
                </Fade>
              </ToolInformation>
            ))}
          </BusinessTool>
        </BusinessScale>

        <TalesHandler>
          <TalesStory>
            <h3>Tales from the Mage Guild</h3>
          </TalesStory>
          <MageStory>
            <LeftMage></LeftMage>
            <CenterMage>
              <ScrollEffect>
                <DivScroll
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <>
                    <>
                      <CommentBox className="comment-box">
                        <CommentAreaDiv>
                          <div>
                            <Navigator>
                              <AiOutlineLeft onClick={handlePrevSlide} />
                            </Navigator>
                            <TextDiv>
                              <p>
                                Mage makes it easy to manage my career. Their
                                financial tools makes running a music business
                                easy. I highly recommend Mage for artistes!
                              </p>
                            </TextDiv>
                            <Navigator>
                              <AiOutlineRight onClick={handleNextSlide} />
                            </Navigator>
                          </div>
                        </CommentAreaDiv>
                        <Commentor>
                          <div>
                            <img src="/andy.jpg" alt="user" />
                            <div>
                              <p>Andy</p>
                              <span>Music Artiste</span>
                            </div>
                          </div>
                        </Commentor>
                      </CommentBox>
                    </>
                  </>
                </DivScroll>

                <DivScroll
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <>
                    <>
                      <CommentBox className="comment-box">
                        <CommentAreaDiv>
                          <div>
                            <Navigator>
                              <AiOutlineLeft onClick={handlePrevSlide} />
                            </Navigator>
                            <TextDiv>
                              <p>
                                “I've been using Mage for a few months
                                now and it's completely transformed the way I
                                send money to my family abroad. The platform is
                                so easy to use and transfers are always fast and
                                secure.”
                              </p>
                            </TextDiv>
                            <Navigator>
                              <AiOutlineRight onClick={handleNextSlide} />
                            </Navigator>
                          </div>
                        </CommentAreaDiv>
                        <Commentor>
                          <div>
                            <img src="/carl.png" alt="user" />
                            <div>
                              <p>Carl Rowan</p>
                              <span>Aglets Inc</span>
                            </div>
                          </div>
                        </Commentor>
                      </CommentBox>
                    </>
                  </>
                </DivScroll>

                <DivScroll
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <>
                    <>
                      <CommentBox className="comment-box">
                        <CommentAreaDiv>
                          <div>
                            <Navigator>
                              <AiOutlineLeft onClick={handlePrevSlide} />
                            </Navigator>
                            <TextDiv>
                              <p>
                                I quit my full time job to focus on freelancing
                                6 months ago. With Mage, I can keep my HMO
                                and Pension without the admin work. Great stuff.
                              </p>
                            </TextDiv>
                            <Navigator>
                              <AiOutlineRight onClick={handleNextSlide} />
                            </Navigator>
                          </div>
                        </CommentAreaDiv>
                        <Commentor>
                          <div>
                            <img src="/ali.jpeg" alt="user" />
                            <div>
                              <p>Femi Ali</p>
                              <span>Developer</span>
                            </div>
                          </div>
                        </Commentor>
                      </CommentBox>
                    </>
                  </>
                </DivScroll>
              </ScrollEffect>
            </CenterMage>
            <RightMage></RightMage>
          </MageStory>
        </TalesHandler>

        <Questionaira>
          <Frequently>
            <div>
              <>
                <h3>Frequently asked questions</h3>
              </>
            </div>
          </Frequently>
          <QuestionsAre>
            {questions.map((question) => (
              <Questions key={question.id}>
                <div onClick={() => handleToggle(question.id)}>
                  <>
                    <p>{question.text}</p>
                  </>
                  <span onClick={() => handleToggle(question.id)}>
                    {toggledQuestion === question.id ? (
                      <>
                        <AiOutlineUp
                          onClick={() => handleToggle(question.id)}
                          color="white"
                          size={20}
                          strokeWidth={6}
                        />
                      </>
                    ) : (
                      <>
                        <AiOutlineDown
                          onClick={() => handleToggle(question.id)}
                          color="white"
                          size={20}
                        />
                      </>
                    )}
                  </span>
                </div>
                {toggledQuestion === question.id && (
                  <>
                    <AnswerContainer
                      isVisible={toggledQuestion === question.id}
                    >
                      <span>
                        <>{question.answer}</>
                      </span>
                    </AnswerContainer>
                  </>
                )}
              </Questions>
            ))}
          </QuestionsAre>
        </Questionaira>

        <AppCorner>
          <DisplayArea>
            <div>
              <AbsoluteImage
                mobile="none"
                top="0"
                width="0"
                size="0"
                left="-9%"
                image="20px"
              >
                <span>
                  <Bounce triggerOnce>
                    <img src="/thunder.png" alt="money" />
                  </Bounce>
                </span>
              </AbsoluteImage>
              <AbsoluteImage
                mobile="none"
                top="0"
                width="0"
                size="0"
                left="77%"
                style={{
                  marginTop: "80px",
                }}
              >
                <span>
                  <>
                    <img src="/money.png" alt="money" />
                  </>
                </span>
              </AbsoluteImage>
              <h3>
                It's your dream,
                <br />
                we just fund it
              </h3>
              <div style={{ cursor: "pointer" }}>
               
                <ButtonPress
                  style={{
                    backgroundColor: "#F2F2F2",
                    color: "#6941C6",
                  }}
                  onClick={() => handleButtonClick('sign-up')}
                >
                  Get Started
                </ButtonPress>
              </div>
            </div>
          </DisplayArea>
        </AppCorner>

        <Footer>
          <FooterContent>
            <MerchroNavsArea>
              <LogoArea>
                <div>
                  <i>
                    <>
                      <img src="/logofooter.png" alt="logo" onClick={handleScrollToBusinessScale} />
                    </>
                  </i>
                  <div>
                    <i>
                        <p>Financial Studio for Professional Creatives</p>
                    </i>
                  </div>
                </div>
              </LogoArea>
              <UseFulArea>
                <div>
                  <>
                    <p>Contact us</p>
                  </>
                  <>
                    <span>hello@trymage.com</span>
                  </>
                  <>
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      +1 22 5239 2278
                    </span>
                  </>
                  <>
                    <div>
                    <a href="https://twitter.com/try_mage" target="_blank" rel="noreferrer"><FaTwitter color="#fff" size={25} /></a>
                    <a href="https://instagram.com/try_mage" target="_blank" rel="noreferrer"><FaInstagram color="#fff" size={25} /></a>
                    </div>
                  </>
                </div>
               
              </UseFulArea>
            </MerchroNavsArea>
            <MerchroEnglish>
              <div>
                <>
                  <span>&copy; Merchro Inc</span>
                </>
              </div>
              <div>
                <>
                  <span></span>{" "}
                </>
              </div>
            </MerchroEnglish>
          </FooterContent>
        </Footer>
      </MainWrapper>
    </>
  );
};

export default MainContent;
