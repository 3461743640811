import "./App.css";
import Home from "./app/";

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
