import React, { useState, useEffect, useRef } from "react";
import {
  AnswerContainer,
  BackCenter,
  BodyInformation,
  BodyWrapper,
  BoxArea,
  BusinessScale,
  BusinessTool,
  ButtonArea,
  ButtonPress,
  ButtonPressBlack,
  Footer,
  FooterContent,
  Frequently,
  Header,
  ImageCollab,
  ImageHolder,
  LogoArea,
  LogoDiv,
  MainWrapper,
  MerchroEnglish,
  MerchroNavsArea,
  NavArea,
  Questionaira,
  Questions,
  QuestionsAre,
  ShortBox,
  ShortTextInBox,
  TextArea,
  ToolInformation,
  UseFulArea,
  ViewImage,
} from "./style";
import {
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import {
  Fade,
  Zoom,
} from "react-awesome-reveal";
import { FaTwitter , FaInstagram } from "react-icons/fa";

interface Question {
  id: number;
  text: string;
  answer: string;
}

const InvestContent = () => {
  const [toggledQuestion, setToggledQuestion] = useState<number | null>(null);

  const handleToggle = (questionId: number) => {
    setToggledQuestion((prev) => (prev === questionId ? null : questionId));
  };

  const questions: Question[] = [
    {
      id: 1,
      text: "What does the minimum $500 investment in Mage Fund cover?",
      answer:
        "A $500 investment allows you to become part of a diversified portfolio that invests in a range of music and content projects, offering a balance of risk and potential return.",
    },
    {
      id: 2,
      text: "How exactly does Mage Fund diversify investments?",
      answer:
        "The fund invests in various creative projects across music and digital content, ensuring a broad exposure to different segments of the creative industry for risk mitigation and potential growth.",
    },
    {
      id: 3,
      text: "What returns should investors typically expect from Mage Fund?",
      answer:
        "While returns can fluctuate, Mage Fund aims for competitive returns aligned with market performance and individual project success rates.",
    },
    {
      id: 4,
      text: "Can investors select specific projects in Mage Fund?",
      answer:
        "Mage Fund operates as a diversified portfolio, and individual project selection is not available. The fund ensures a balanced investment approach on your behalf.",
    },
    {
      id: 5,
      text: "What is the unique proposition of Mage Deal Matching?",
      answer:
        "It allows investors to selectively invest in specific creative deals that are rigorously vetted for potential and stability, offering a more hands-on investment approach.",
    },
    {
      id: 6,
      text: "How does Mage ensure the quality of deals in Mage Deal Matching?",
      answer:
        "Each deal is thoroughly vetted for financial viability, creative potential, and market relevance by our expert team to ensure quality investments.",
    },
    {
      id: 7,
      text: "Is it possible to invest in multiple deals through Mage Deal Matching?",
      answer:
        "Absolutely, investors can diversify their portfolio by selecting and investing in various deals of their choice.",
    },
    {
      id: 8,
      text: "What kind of returns does Mage Deal Matching generally offer?",
      answer:
        "Returns on Mage Deal Matching are deal-specific, depending on the nature of each creative project and its market performance.",
    },
    {
      id: 9,
      text: "Is there flexibility in the minimum investment amount for Mage Deal Matching?",
      answer:
        "Yes, the minimum investment may vary based on the deal’s nature and scope, offering flexibility to investors.",
    },
    {
      id: 10,
      text: "How does Mage secure and monitor investments?",
      answer:
        "Investments are secured against carefully selected and vetted creative projects, with ongoing monitoring and management to safeguard your investment.",
    },
  ];

  const businessScaleRef = useRef<HTMLDivElement>(null);

  const handleScrollToBusinessScale = () => {
    if (businessScaleRef.current) {
      businessScaleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const advantage = [
    {
      image: "/lock.png",
      topic: "Secured Returns",
      desc:
        "Investments are secured with direct cash flows from digital distributors, ensuring straightforward income collections.",
    },
    {
      image: "/report.png",
      topic: "High Growth",
      desc:
        "Streaming accounts for majority of music revenue, and is growing independently of stock market fluctuations.",
    },
    {
      image: "/impact.png",
      topic: "Impact Driven",
      desc:
        "The creative economy creates significant opportunity for diverse talents. You get to support this people directly.",
    },
  ];

  const handleButtonClick = (path = '') => {
    // Link to form
    if (path === 'login') {
      window.open("https://app.trymage.com/login");
      return;
    }
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSde1mz7D5afQSuSi3tafVirOVL8VGneh8LjflKP5WyJj4EyiA/viewform?usp=sf_link", "_blank");
  };

  const handlePathClick = (path:string) => {
    window.location.href = 'https://trymage.com';
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const originalContent = container.children[0];

    const cloneContent = () => {
      const clone = originalContent?.cloneNode(true);
      container?.appendChild(clone);
    };

    const handleAnimationIteration = () => {
      const firstChild = container?.firstChild;
      if (firstChild) {
        container?.removeChild(firstChild);
      }
      cloneContent();
    };
    cloneContent();

    container?.addEventListener("animationiteration", handleAnimationIteration);
    return () => {
      container?.removeEventListener(
        "animationiteration",
        handleAnimationIteration
      );
    };
  }, []);

  return (
    <>
      <MainWrapper>
        <Header ref={businessScaleRef} style={{ background: "#fff", borderColor: '#ccc' }}>
          <Zoom triggerOnce>
            <LogoDiv style={{ cursor: 'pointer' }} onClick={() => handlePathClick('home')}>
              <img src="/Group2.svg" alt="logo" />
            </LogoDiv>
          </Zoom>

          <NavArea></NavArea>
          <ButtonArea>
            {/* <p style={{ color: '#000', marginRight: 20 }} onClick={() => handlePathClick('home')}>Home</p> */}
            <p style={{ color: '#000', marginRight: 20 }} onClick={() => handleButtonClick('form')}>Join our waitlist</p>
          </ButtonArea>
        </Header>

        <BodyWrapper style={{ background: '#fff' }}>
          <BackCenter>
            <ButtonPressBlack>Invest in creatives</ButtonPressBlack>
            <TextArea style={{ width: "auto" }}>
              <h3 style={{ width: '100%', color: '#000' }} className="close-text">Unlock a new passive income stream</h3>
              <p style={{ color: '#000' }}>
                Mage makes it easy to find and fund
                <br /> profitable creatives across Africa.
              </p>

              <ButtonPress onClick={() =>handleButtonClick()}>Join our waitlist</ButtonPress>
            </TextArea>
          </BackCenter>
          <ViewImage>
            <img style={{ width: '95%', margin: '0 2.5%' }} src="/invest.png" alt="invest" />
          </ViewImage>
        </BodyWrapper>

        <BodyInformation>
          <ImageCollab>
            <img src="/groups5.png" alt="collab" />
          </ImageCollab>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextArea width="65%" bottom="70px">
              <>
                <h2
                  style={{
                    color: "#222",
                  }}
                  className="close-text"
                >
                  We offer diverse investment choices that suits you
                </h2>
                <p style={{ color: "#222" }}>
                    Join Mage’s Investor Network, where investing in creatives is not just profitable but also impactful
                </p>
              </>
            </TextArea>
          </div>

          <BoxArea>
            <ShortBox className="invest-box">
              <ShortTextInBox>
                <div>
                  <img src="/c-collect.svg" alt="logo" />
                </div>
                <p style={{ margin: '15px 0' }}>Creative Collective</p>
                <span>
                    Join a network of institutional, corporate, and high-net-worth investors, all committed to supporting a diverse range of creative projects, while expecting substantial returns on their investments.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <ShortBox className="invest-box">
              <ShortTextInBox>
                <div>
                  <img src="/c-connect.svg" alt="logo" />
                </div>
                <p style={{ margin: '15px 0' }}>Creative Connect</p>
                <span>
                    Get hands-on with individual deals in the creative realm, from unique invoice financing to exclusive catalogue purchases. Perfect for those who love a more personalised investment touch.
                </span>
              </ShortTextInBox>
            </ShortBox>
            <div style={{ display: 'flex', justifyContent: "center", textAlign: 'center', width: '100%' }}>
                <ButtonPress style={{ marginTop: 20, marginBottom: -20 }} onClick={() => handleButtonClick()}>Join our waitlist</ButtonPress>
            </div>
          </BoxArea>
        </BodyInformation>

        <BusinessScale style={{ background: '#fff' }}>
          <ImageHolder>
            <img src="/picmix.png" alt="landing" />
          </ImageHolder>
          <TextArea width="65%" bottom="70px">
              <>
                <h2
                  style={{
                    color: "#222",
                    marginTop: 30
                  }}
                  className="close-text"
                >
                  Why you should invest in the creative sector
                </h2>
              </>
            </TextArea>
          <BusinessTool>
            {advantage.map((info, index) => (
              <ToolInformation key={index} style={{ margin: '0 0 60px 0' }}>
                <div style={{ position: "relative" }}>
                  <div style={{ display: 'block', position: 'absolute', top: -50 }}>
                    {/* <Reveal> */}
                      <img src={info.image} style={{ width: 40 }} alt="stack" />
                    {/* </Reveal> */}
                  </div>
                  <p style={{ color: '#000', fontWeight: 500, display: 'block' }}>{info.topic}</p>
                </div>
                <Fade>
                  <span style={{ color: '#2F2C2C' }}>{info.desc}</span>
                </Fade>
              </ToolInformation>
            ))}
          </BusinessTool>
        </BusinessScale>

        <Questionaira style={{ background: '#fff' }}>
          <Frequently>
            <div>
                <h3 style={{ color: "#000" }} className="close-text">Frequently asked questions</h3>
            </div>
          </Frequently>
          <QuestionsAre>
            {questions.map((question, index) => (
              <Questions key={index} style={{ borderColor: '#000'}}>
                <div onClick={() => handleToggle(question.id)}>
                  <p style={{ color: '#000' }}>{question.text}</p>
                  <span onClick={() => handleToggle(question.id)}>
                    {toggledQuestion === question.id ? (
                      <AiOutlineUp
                        onClick={() => handleToggle(question.id)}
                        color="black"
                        size={20}
                        strokeWidth={6}
                      />
                    ) : (
                      <AiOutlineDown
                        onClick={() => handleToggle(question.id)}
                        color="black"
                        size={20}
                      />
                    )}
                  </span>
                </div>
                {toggledQuestion === question.id && (
                  <>
                    <AnswerContainer
                      isVisible={toggledQuestion === question.id}
                    >
                      <span style={{ color: '#2f2f2f', fontSize: 20, width: '80%' }}>
                        {question.answer}
                      </span>
                    </AnswerContainer>
                  </>
                )}
              </Questions>
            ))}
          </QuestionsAre>
        </Questionaira>

        <Footer>
          <FooterContent>
            <MerchroNavsArea>
              <LogoArea>
                <div>
                  <i>
                    <>
                      <img src="/logofooter.png" alt="logo" onClick={handleScrollToBusinessScale} />
                    </>
                  </i>
                  <div>
                    <i>
                      <>
                        <p>Smart Funding for Creative Projects</p>
                      </>
                    </i>
                  </div>
                </div>
              </LogoArea>
              <UseFulArea>
                <div>
                  <>
                    <p>Contact us</p>
                  </>
                  <>
                    <span>hello@trymage.com</span>
                  </>
                  <>
                    <span
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      +1 22 5239 2278
                    </span>
                  </>
                  <>
                    <div>
                    <a href="https://twitter.com/try_mage" target="_blank" rel="noreferrer"><FaTwitter color="#fff" size={25} /></a>
                    <a href="https://instagram.com/try_mage" target="_blank" rel="noreferrer"><FaInstagram color="#fff" size={25} /></a>
                    </div>
                  </>
                </div>
               
              </UseFulArea>
            </MerchroNavsArea>
            <MerchroEnglish>
              <div>
                <>
                  <span>&copy; Merchro Inc</span>
                </>
              </div>
              <div>
                <>
                  <span></span>{" "}
                </>
              </div>
            </MerchroEnglish>
          </FooterContent>
        </Footer>
      </MainWrapper>
    </>
  );
};

export default InvestContent;
