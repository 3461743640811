import React, { useEffect, useState } from 'react';
import MainContent from "./pages";
import InvestContent from "./pages/invest";

const Home = () => {
  const [activePath, setActivePath] = useState('');
  useEffect(() => {
    if (window.location.href.toString().indexOf('invest.') > -1) {
      setActivePath('invest')
    } else setActivePath('home')
  }, [])
  return (
    <>
      { activePath === 'invest' ? <InvestContent /> : activePath === 'home' ? <MainContent /> : null }
    </>
  );
};

export default Home;
